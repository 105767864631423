<template>
  <div id="add-event-image">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Title Name
                    </h6>
                  </label>
                  <v-text-field
                    v-model="TitleName"
                    :rules="TitleNameRules"
                    placeholder="Title Name"
                    hint="Enter Title Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Title Short Txt
                    </h6>
                  </label>
                  <v-text-field
                    v-model="TitleShortTxt"
                    :rules="TitleShortTxtRules"
                    placeholder="Title Short Txt"
                    hint="Enter Title Short Txt"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Title Amount
                    </h6>
                  </label>
                  <v-text-field
                    v-model="TitleAmount"
                    :rules="TitleAmountRules"
                    min="0"
                    type="number"
                    hint="Enter the Title Amount"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>
                      Tax
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="TitleTax"
                    min="0"
                    type="number"
                    hint="Enter the Tax"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>Upgrade Charge</h6>
                  </label>
                  <v-text-field
                    v-model="UpgradeCharge"
                    min="0"
                    type="number"
                    hint="Enter the Upgrade Charge"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Start Date
                    </h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="StartDate"
                        :rules="StartDateRules"
                        readonly
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="StartDate" @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4">
                  <label>
                    <h6>Status</h6>
                  </label>
                  <v-switch
                    inset
                    v-model="TitleStatus"
                    :label="`${
                                TitleStatus
                                  ? 'Enabled'
                                  : 'Disabled'
                              }`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6>
                    <span class="text-danger">*</span> Title Image
                  </h6>
                  <v-file-input
                    :clearable="false"
                    truncate-length="25"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Upload Title Photo"
                    append-outer-icon="mdi-delete"
                    v-model="TitleImage"
                    @change="addImage(1)"
                    @click:append-outer="deleteImage(1)"
                  ></v-file-input>
                </v-col>
                <v-col align="center" cols="12" sm="6" lg="4" md="4">
                  <h6>Image Edit Options</h6>
                  <br />
                  <div class="actions">
                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="flip(true, false)">
                        <v-icon>fa fa-caret-right</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="flip(false, true)">
                        <v-icon>fa fa-caret-up</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="rotate(90)">
                        <v-icon>fa fa-redo</v-icon>
                      </v-btn>
                    </b-button-group>

                    <b-button-group vertical class="mr-3 mb-3">
                      <v-btn fab dark small color="indigo" @click.prevent="rotate(-90)">
                        <v-icon>fa fa-undo</v-icon>
                      </v-btn>
                    </b-button-group>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h4>Uploaded image will appear here</h4>
                  <cropper
                    ref="cropper"
                    :src="SelectedImage.ImageUrl"
                    :stencil-props="{
                            handlers: {},
                            movable: false,
                            scalable: false,
                            aspectRatio: 1,
                            previewClass: 'preview',
                        }"
                    :stencil-size="{
                            width: 450,
                            height: 300,
                        }"
                    image-restriction="stencil"
                  />
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#a52a2a"
                    class="font-size-h6 px-5 mr-2 white--text"
                  >Close</v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="getCroppedImage"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Save</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      ProgressLoadingFlag: false,
      LoadingFlag: false,
      SubmitFlag: false,

      TitleName: "",
      TitleShortTxt: "",
      TitleAmount: 0,
      TitleTax: 0,
      UpgradeCharge: 0,
      TitleStatus: 0,

      menu1: false,
      StartDate: new Date().toISOString().slice(0, 10),
      StartDateRules: [v => !!v || "Start Date is required"],

      TitleNameRules: [v => !!v || "Title Name is required"],
      TitleShortTxtRules: [v => !!v || "Short Txt is required"],
      TitleAmountRules: [
        v => !!v || "Amount is required",
        v => v > 0 || "Amount is required"
      ],

      TitleImage: {},
      SelectedImage: {
        ImageType: "",
        ImageUrl: null
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;
      }
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "foundation_title",
        Action: "create"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.TitleImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null
          };
          this.TitleImage = {};
          break;

        default:
          break;
      }
    },
    getCroppedImage() {
      console.log("getCroppedImage called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        let upload = new FormData();

        var TitleTax = this.TitleTax >= 0 ? this.TitleTax : 0;
        var UpgradeCharge = this.UpgradeCharge >= 0 ? this.UpgradeCharge : 0;

        upload.append("UserInterface", 1);
        var Form = {
          TitleName: this.TitleName,
          TitleShortTxt: this.TitleShortTxt,
          TitleAmount: this.TitleAmount,
          TitleTax: TitleTax,
          UpgradeCharge: UpgradeCharge,
          StartDate: this.StartDate,
          TitleStatus: this.TitleStatus,
          YearCode: this.JciYearCode
        };

        upload.append("Form", JSON.stringify(Form));

        var file = this.TitleImage;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        if (FileName == undefined || FileName == null || FileName == "") {
          message = "Kindly select image. ";
          this.sweetAlert("error", message, false);
        } else {
          const { canvas } = this.$refs.cropper.getResult();
          if (canvas) {
            canvas.toBlob(blob => {
              upload.append("TitleImage", blob, FileName);
              this.submitForm(upload);
            }, FileType);
          } else {
            message = "Kindly select image. ";
            this.sweetAlert("error", message, false);
          }
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm(upload) {
      console.log("submitForm is called");

      console.log({ upload });

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/yearwise-title/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
          "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>