<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-dialog v-model="addRecordPrompt" persistent scrollable>
        <foundation-title-create
          :addRecordPrompt="addRecordPrompt"
          @hideDialog="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></foundation-title-create>
      </v-dialog>

      <v-dialog v-model="TitleAmountEditRecordPrompt" persistent scrollable>
        <foundation-title-amount-modify
          PageTile="Foundation Title Amount Modify"
          :TitleAmountEditRecordPrompt="TitleAmountEditRecordPrompt"
          @hideDialog="hideTitleAmountEditRecordPrompt"
          v-if="TitleAmountEditRecordPrompt"
        ></foundation-title-amount-modify>
      </v-dialog>

      <v-dialog v-model="TitleEditRecordPrompt" persistent scrollable>
        <foundation-title-edit
          :TitleEditRecordPrompt="TitleEditRecordPrompt"
          :recordData="selectedData"
          @hideDialog="hideTitleEditRecordPrompt"
          v-if="TitleEditRecordPrompt"
        ></foundation-title-edit>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0">
          <v-row wrap>
            <v-col cols="12" md="12" align="center">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                color="#EDBE38"
                class="btn btn-primary font-size-h6 my-3 mr-3 white--text"
                @click.prevent="refreshPageData"
              >Refresh</v-btn>
              <v-btn
                @click.prevent="addRecordPrompt = true"
                color="#8950FC"
                elevation="30"
                shaped
                tile
                small
                v-if="tableOptions.CreateButtonFlag"
                class="btn btn-primary font-size-h6 my-3 mr-3 white--text"
              >Title Create</v-btn>
              <v-btn
                @click.prevent="TitleAmountEditRecordPrompt = true"
                color="#8950FC"
                elevation="30"
                shaped
                tile
                small
                v-if="tableOptions.EditButtonFlag"
                class="btn btn-primary font-size-h6 my-3 mr-3 white--text"
              >Modify Title Amount</v-btn>
            </v-col>
          </v-row>
          <br />
          <v-row wrap v-if="!ResultFlag && tableData.length == 0">
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
          <br />
          <v-row wrap v-if="tableData.length > 0">
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData.length }} records found.</h4>
            </v-col>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions.ItemKey"
                :single-select="tableOptions.SingleSelectFlag"
                :show-select="tableOptions.ShowSelectFlag"
                :headers="tableColumns"
                :items="tableData"
                :items-per-page="tableOptions.ItemsPerPage"
                :footer-props="tableOptions.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                  >{{ item.ActiveStatusTxt }}</v-chip>
                </template>
                <template v-slot:item.TitleFilePath="{ item }">
                  <img width="120" height="80" :src="item.TitleFilePath" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-if="item.EditFlag"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-document-edit</v-icon>
                    </template>
                    <span>Title Edit</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import FoundationTitleCreate from "@/view/pages/erp/foundation/titles/FoundationTitleCreate.vue";
import FoundationTitleEdit from "@/view/pages/erp/foundation/titles/FoundationTitleEdit.vue";
import FoundationTitleAmountModify from "@/view/pages/erp/foundation/titles/FoundationTitleAmountModify.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    FoundationTitleCreate,
    FoundationTitleEdit,
    FoundationTitleAmountModify
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      search: "",
      tableColumns: [],
      tableOptions: {},
      tableData: [],

      selectedData: {},
      selected: {},

      addRecordPrompt: false,
      TitleEditRecordPrompt: false,
      TitleAmountEditRecordPrompt: false,
      previewRecordPrompt: false,

      ZoneCode: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      CurrentYearId: 0,
      YearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;
        this.JciYearCode = CurrentJciYearId;

        this.getTableRecords();
      }
    }
  },
  created() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "foundation_title",
        Action: "list"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.getTableRecords();
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hideTitleEditRecordPrompt() {
      console.log("hideTitleEditRecordPrompt called");
      this.TitleEditRecordPrompt = false;
      this.getTableRecords();
    },
    hideTitleAmountEditRecordPrompt() {
      console.log("hideTitleAmountEditRecordPrompt called");
      this.TitleAmountEditRecordPrompt = false;
      this.getTableRecords();
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.TitleEditRecordPrompt = true;
      } else {
        var message = "Kindly select one member to edit";
        this.toast("error", message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.selected = [];
      this.tableData = [];

      var YearCode = this.JciYearCode;
      console.log("YearCode=" + YearCode);

      if (YearCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/yearwise-title/list";
        var upload = {
          UserInterface: 1,
          YearCode: YearCode
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;
        this.SearchFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = records.TableOptions;

            if (flag == 1) {
              thisIns.tableColumns = records.TableHeader;
              thisIns.tableOptions = options;
              thisIns.tableData = records.TableData;
            } else {
              console.log("error=" + output);
            }
          })
          .catch(function(error) {
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            console.log("error=" + error);
          });
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>

<style lang="scss">
</style>
