<template>
  <div id="add-event-image">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Title
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="TitleCodeOptionsLoading"
                    :items="TitleCodeOptions"
                    :rules="TitleCodeRules"
                    v-model="YearwiseTitleCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" v-if="TitleName != ''">
                  <h6>Title Name</h6>
                  <p>{{TitleName}}</p>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" v-if="TitleShortTxt != ''">
                  <h6>Title Short Txt</h6>
                  <p>{{TitleShortTxt}}</p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3" v-if="TitleImgUrl != ''">
                  <h6>Title Image</h6>
                  <img width="120" height="80" :src="TitleImgUrl" />
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Start Date
                    </h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="StartDate"
                        :rules="StartDateRules"
                        readonly
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="StartDate" @input="menu1 = false" :min="MaxStartDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> End Date
                    </h6>
                  </label>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="EndDate"
                        :rules="EndDateRules"
                        readonly
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="EndDate" @input="menu2 = false" :min="MaxStartDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Title Amount
                    </h6>
                  </label>
                  <v-text-field
                    v-model="TitleAmount"
                    :rules="TitleAmountRules"
                    min="0"
                    type="number"
                    hint="Enter the Title Amount"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>
                      Tax
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-text-field
                    v-model="TitleTax"
                    min="0"
                    type="number"
                    hint="Enter the Tax"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>Upgrade Charge</h6>
                  </label>
                  <v-text-field
                    v-model="UpgradeCharge"
                    min="0"
                    type="number"
                    hint="Enter the Upgrade Charge"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <label>
                    <h6>Status</h6>
                  </label>
                  <v-switch
                    inset
                    v-model="TitleStatus"
                    :label="`${
                                TitleStatus
                                  ? 'Enabled'
                                  : 'Disabled'
                              }`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#a52a2a"
                    class="font-size-h6 px-5 mr-2 white--text"
                  >Close</v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Save</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      ProgressLoadingFlag: false,
      LoadingFlag: false,
      SubmitFlag: false,

      TitleCodeRules: [v => !!v || "Title is required"],
      TitleCode: "",
      YearwiseTitleCode: "",
      TitleCodeOptions: [],
      TitleCodeOptionsLoading: false,

      menu1: false,
      StartDate: new Date().toISOString().slice(0, 10),
      StartDateRules: [v => !!v || "Start Date is required"],
      MaxStartDate: new Date().toISOString().slice(0, 10),

      menu2: false,
      EndDate: new Date().toISOString().slice(0, 10),
      EndDateRules: [v => !!v || "Start Date is required"],

      TitleName: "",
      TitleShortTxt: "",
      TitleAmount: 0,
      TitleTax: 0,
      UpgradeCharge: 0,
      TitleStatus: 0,

      TitleNameRules: [v => !!v || "Title Name is required"],
      TitleShortTxtRules: [v => !!v || "Short Txt is required"],
      TitleAmountRules: [
        v => !!v || "Amount is required",
        v => v > 0 || "Amount is required"
      ],

      TitleImgUrl: "",
      TitleImage: {},
      SelectedImage: {
        ImageType: "",
        ImageUrl: null
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        var Today = new Date();
        var Month =
          Today.getMonth() < 9
            ? "0" + (Today.getMonth() + 1)
            : Today.getMonth() + 1;
        Today.setDate(Today.getDate() + 1);
        var Tomorrow =
          Today.getFullYear() + "-" + Month + "-" + Today.getDate();
        console.log({ Tomorrow });
        this.StartDate = Tomorrow;
        this.EndDate = Tomorrow;
        this.MaxStartDate = Tomorrow;

        this.getTitleCodeOptions();
      }
    },
    TitleCodeOptions: function() {
      console.log("watch TitleCodeOptions");
      this.TitleCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    YearwiseTitleCode: function() {
      console.log("watch YearwiseTitleCode");

      var temp_code = this.YearwiseTitleCode;
      console.log("temp_code=" + temp_code);
      var idx = this.TitleCodeOptions.map(e => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var recordData = this.TitleCodeOptions[idx];
        console.log("recordData=" + JSON.stringify(recordData));

        this.TitleCode = recordData.TitleId;
        this.TitleName = recordData.TitleName;
        this.TitleShortTxt = recordData.TitleShortTxt;
        this.TitleAmount = recordData.TitleAmount;
        this.TitleTax = recordData.TitleTax;
        this.UpgradeCharge = recordData.UpgradeCharge;
        this.TitleStatus = recordData.ActiveStatus;
        this.TitleImgUrl = recordData.TitleFilePath;

        console.log(
          "StartDate=" + this.StartDate + "EndDate=" + recordData.EndDate
        );

        var Date1 = new Date();
        var EndDate =
          this.StartDate > recordData.TitleEndDate
            ? Date1.getFullYear() + "-12-31"
            : recordData.EndDate;
        console.log("EndDate=" + EndDate);

        this.EndDate = EndDate;
      }
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "foundation_title",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getTitleCodeOptions() {
      console.log("getTitleCodeOptions called");
      this.TitleCodeOptionsLoading = true;
      var selectbox1_source = "TitleCode";
      var selectbox1_destination = "TitleCodeOptions";
      var selectbox1_url = "api/wm/yearwise/titles/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        YearCode: this.JciYearCode
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SubmitFlag = true;
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/yearwise-title/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var TitleTax = this.TitleTax >= 0 ? this.TitleTax : 0;
        var UpgradeCharge = this.UpgradeCharge >= 0 ? this.UpgradeCharge : 0;

        var upload = {
          UserInterface: 1,
          TitleId: this.TitleCode,
          StartDate: this.StartDate,
          EndDate: this.EndDate,
          TitleAmount: this.TitleAmount,
          TitleTax: TitleTax,
          UpgradeCharge: UpgradeCharge,
          TitleStatus: this.TitleStatus
        };
        console.log({ upload });

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>